const FeedTypeData = [
  {
    name: 'Mains / Potable / Softened',
    description: '',
    colourClass: 'bgYellow'
  },
  {
    name: 'Pretreated Water',
    description: 'Reverse Osmosis and Deionised water.',
    colourClass: 'bgPurple'
  },
  {
    name: "Don't Know",
    description: '',
    colourClass: 'bgOrange'
  }
];

export default FeedTypeData
