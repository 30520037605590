import React, { useContext } from "react";
import { Link, withRouter, useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import ApplicationData from "./data/ApplicationData";
import WaterTypeData from "./data/WaterTypeData";
import { ChoicesContext } from "../context/ChoicesProvider";
import { ReactComponent as Logo } from "../images/logo.svg";
import { ReactComponent as WaterTechLogo } from "../images/water-tech-logo.svg";
import DotsButton from "./DotsBtn";

const WaterType = () => {
  let history = useHistory();
  const { choices, setChoices } = useContext(ChoicesContext);
  const CurrentApplication = ApplicationData.find((x) => x.name === choices.category);
  const { waterTypes } = CurrentApplication;

  return (
    <>
      <Link to="/">
        <Logo className="Logo" />
      </Link>
      <Link to="/">
        <WaterTechLogo className="WaterTechLogo" />
      </Link>

      <div className="pageLinks">
        <div className="breadcrumb">Applications > {choices.category}</div>
        <DotsButton />
      </div>

      <div className="waterTypes wrapper d-md-flex">
        <aside>
          <h2>
            Types of <br />
            Water
          </h2>
        </aside>

        <main>
          <h3>We recommend the following water type...</h3>
          <div className="boxLinks">
            <div className="row">
              {WaterTypeData.map((type, i) => (
                <div
                  key={i}
                  className={`col-md-6 col-xl-4 boxLink ${
                    waterTypes.includes(type.name) ? "" : "unavailable"
                  }`}>
                  <div className={`${type.colourClass} wrapper`}>
                    {waterTypes.includes(type.name) ? (
                      <Link
                        onClick={() => setChoices({ ...choices, waterType: type.name })}
                        to={{
                          pathname: "/feedType",
                        }}>
                        <h4>{type.name}</h4>
                        <p>{type.description}</p>
                        <p className="select">
                          <FontAwesomeIcon icon={faArrowRight} /> Select
                        </p>
                      </Link>
                    ) : (
                      <Link
                        to={{
                          pathname:
                            "https://www.veoliawatertechnologies.co.uk/campaign-forms/chorus",
                        }}
                        target="_blank">
                        <h4>{type.name} (Not Recommended)</h4>
                        <p>
                          If you want more infomation as to why we dont recommend this type of
                          water, please contact us.
                        </p>
                        <p className="select">
                          <FontAwesomeIcon icon={faArrowRight} /> Request More Info
                        </p>
                      </Link>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </main>

        <Link
          onClick={() => history.goBack()}
          className="backBtn">
          <FontAwesomeIcon icon={faArrowLeft} />
        </Link>
      </div>
    </>
  );
};

export default withRouter(WaterType);
