import Chorus1Img from '../../images/sequences/chorus_1_static.jpg'
import Chorus1CompleteImg from '../../images/sequences/chorus_1_complete_static.jpg'
import Chorus2Img from '../../images/sequences/chorus_2_static.jpg'
import Chorus2PlusImg from '../../images/sequences/chorus_2_plus_static.jpg'
import Chorus13Img from '../../images/sequences/chorus_3_static.jpg'

const ProductData = [
  {
    id: 1,
    name: 'Chorus 1',
    slug: 'chorus-1',
    static_img: Chorus1Img,
    explore_tech_img_folder: 'chorus_1_explore_technologies',
    explore_tech_filename: 'chorus_1_explore_technologies',
    user_interface_img_folder: 'chorus_1_user_interface',
    user_interface_filename: 'chorus_1_user_interface',
    process_flow_title: 'Process Flow PURELAB Chorus 1 - Ultrapure Water for Analytical Research Applications',
    process_flow_vimeo: 'https://player.vimeo.com/external/426953007.hd.mp4?s=2fc757729531be16b4d80eedd92fdb9389be87e9&profile_id=175',
    categories: [
      'Atomic Spectroscopy',
      'GC,MS, GF-AAS',
      'Mammalian Cell Culture',
      'Molecular Biology',
      'Tissue Culture',
      'DNA/RNA Work',
      'Endotoxin Analysis',
      'Immunocytochemistry',
      'Electrophoresis',
      'Cell Cultures',
      'Electrochemistry',
      'Gas Chromatography',
      'General Chemistry',
      'General Lab Water',
      'Genetic',
      'Immunochemistry',
      'Liquid Chromatography',
      'Mass Spectrometry',
      'Microbiological Analysis',
      'Life Science',
      'HPLC',
      'TOC',
      'Spectrophotometry'
    ],
    waterTypes: [
      'Type 1',
      "Don't Know"
    ],
    feedTypes: [
      'Pretreated Water',
      "Don't Know"
    ],
    keyFeatures: [
      'Polisher',
      'Type 1 ultra pure water',
      'Reliable and flexible',
      '18.2 MΩ',
      '1-120 Liters per day'
    ],
    colourClass: 'bgPurple',
    uiBtnClass: 'btn',
    etBtnClass: 'btn-reverse',
    featureBtnClass: 'showfeatureBtns'
  },
  {
    id: 2,
    name: 'Chorus 1 Complete',
    slug: 'chorus-1-complete',
    static_img: Chorus1CompleteImg,
    explore_tech_img_folder: 'chorus_1_complete_explore_tech',
    explore_tech_filename: 'chorus_1_complete_explore_tech',
    user_interface_img_folder: 'chorus_1_complete_user_interface',
    user_interface_filename: 'chorus_1_complete_user_interface',
    process_flow_title: 'Process Flow for PURELAB Chorus 1 Complete',
    process_flow_vimeo: 'https://player.vimeo.com/external/426952791.hd.mp4?s=bcce4d1e7c454a065207407fa22d8f7a8a247812&profile_id=175',
    categories: [
      'Atomic Spectroscopy',
      'GC,MS, GF-AAS',
      'Mammalian Cell Culture',
      'Molecular Biology',
      'Tissue Culture',
      'DNA/RNA Work',
      'Endotoxin Analysis',
      'Immunocytochemistry',
      'Electrophoresis',
      'Electrochemistry',
      'Gas Chromatography',
      'General Chemistry',
      'General Lab Water',
      'Genetic',
      'Immunochemistry',
      'Liquid Chromatography',
      'Mass Spectrometry',
      'Microbiological Analysis',
      'Spectrophotometry',
      'Life Science',
      'HPLC',
      'TOC',
      'Cell Cultures'
    ],
    waterTypes: ['Type 1', "Don't Know"],
    feedTypes: ['Mains / Potable / Softened', "Don't Know"],
    keyFeatures: [
      'One complete solution for the lab',
      'Tap to Type 1 ultra pure water',
      'Reliable and flexible',
      '18.2 MΩ',
      '1-100 Liters per day'
    ],
    colourClass: 'bgPurple',
    uiBtnClass: 'btn-reverse',
    etBtnClass: 'btn'
  },
  {
    id: 4,
    name: 'Chorus 2+',
    slug: 'chorus-2-plus',
    static_img: Chorus2PlusImg,
    explore_tech_img_folder: 'chorus_2_plus_explore_tech',
    explore_tech_filename: 'chorus_2_plus_explore_tech',
    user_interface_img_folder: 'chorus_2_plus_user_interface',
    user_interface_filename: 'chorus_2_plus_user_interface',
    process_flow_title: 'Process Flow PURELAB Chorus 2+ (RO/EDI/UV)',
    process_flow_vimeo: 'https://player.vimeo.com/external/426953081.hd.mp4?s=d9777c92d8d830446cc924725dd94f43c083946f&profile_id=175',
    categories: [
      'Cell Cultures',
      'Life Science',
      'HPLC',
      'Microbiological Analysis'
    ],
    waterTypes: ['Type 2', "Don't Know"],
    feedTypes: ['Mains / Potable / Softened', "Don't Know"],
    keyFeatures: [
      'Tap to Type II & Type 2+ water',
      'Fully recirculating',
      'Multiple dispensing',
      '>15 MΩ',
      '1-120 Liters per day'
    ],
    colourClass: 'bgTurquoise',
    uiBtnClass: 'btn-reverse',
    etBtnClass: 'btn'
  },
  {
    id: 3,
    name: 'Chorus 2',
    slug: 'chorus-2',
    static_img: Chorus2Img,
    explore_tech_img_folder: 'chorus_2_explore_technologies',
    explore_tech_filename: 'chorus_2_explore_technologies',
    user_interface_img_folder: 'chorus_2_user_interface',
    user_interface_filename: 'chorus_2_user_interface',
    process_flow_title: 'Process Flow PURELAB Chorus 2 (RO/DI) - Pure Water for General Laboratory Applications',
    process_flow_vimeo: 'https://player.vimeo.com/external/426953177.hd.mp4?s=b8814ab98cbba2c1c674ca799a3d577f304b5c05&profile_id=175',
    categories: [
      'General Chemistry',
      'General Lab Water',
      'Glass Ware Washing',
      'Media Preparation',
      'Hydroponics',
      'Steam Generation'
    ],
    waterTypes: ['Type 2', "Don't Know"],
    feedTypes: ['Mains / Potable / Softened', "Don't Know"],
    keyFeatures: [
      'Tap to Type II water',
      'Feed water for Ultra Pure Water',
      'Reliable and flexible',
      '10 MΩ',
      '10-480 Liters per day'
    ],
    colourClass: 'bgTurquoise',
    uiBtnClass: 'btn-reverse',
    etBtnClass: 'btn'
  },
  {
    id: 5,
    name: 'Chorus 3',
    slug: 'chorus-3',
    static_img: Chorus13Img,
    explore_tech_img_folder: 'chorus_3_explore_technologies',
    explore_tech_filename: 'chorus_3_explore_technologies',
    user_interface_img_folder: 'chorus_3_user_interface',
    user_interface_filename: 'chorus_3_user_interface',
    process_flow_title: 'Process Flow PURELAB Chorus 3 (RO) - General Grade Water for Laboratory Application',
    process_flow_vimeo: 'https://player.vimeo.com/external/426953257.hd.mp4?s=d7f3ab693be80d6683acfebcc34c0b88521e1f5c&profile_id=175',
    categories: [
      'General Chemistry',
      'General Lab Water',
      'Glass Ware Washing',
      'Media Preparation',
      'Hydroponics',
      'Steam Generation'
    ],
    waterTypes: ['Type 3', "Don't Know"],
    feedTypes: ['Mains / Potable / Softened', "Don't Know"],
    keyFeatures: [
      'Type III general grade water',
      'RO water',
      'Feed water for Ultra Pure Water',
      'Reliable and flexible',
      '10-720 Liters per day'
    ],
    colourClass: 'bgGreen',
    uiBtnClass: 'btn-reverse',
    etBtnClass: 'btn'
  },
];

export default ProductData
