import React, { useEffect, useState } from "react";

const Chorus2SeqImages = props => {
  const [images, setImages] = useState({});
  const [imagesReadyCnt, setImagesReadyCnt] = useState(0);  // counter of preloaded images

  useEffect(() => {
    const importedImages = {};
    let i = 0;
    const r = require.context(
                "../../images/sequences/chorus_2", // relative path to folder with images, that we want to be imported and preloaded
                false, // with subfolders or not
                /\.(png|jpe?g|svg)$/ // extensions of files
              );
    r.keys().forEach(item => {
      const importedImg = r(item); // import image
      importedImages[item.replace("./", "")] = importedImg; // name of file will be a key, path to file will be a value
      const img = new Image();
      img.onload = () => {
        i++;
        setImagesReadyCnt(i); // increase counter when image is loaded
      };
      img.src = importedImg;
    });
    setImages(importedImages);
  }, []);

  // if images are not loaded yet( means that counter != number of files in imported folder))
  if (Object.keys(images).length !== imagesReadyCnt || imagesReadyCnt < 1) {
    return (
      <div class="loading">
        <div class="spinner">
          <div class="double-bounce1"></div>
          <div class="double-bounce2"></div>
        </div>
      </div>
    );
  }

  return (
    <>
      <img src={images['chorus_2_explore_technologies_0000.jpg']} className="sequenceImage exploreTech" alt="" />
      <img src={images['chorus_2_explore_technologies_0001.jpg']} className="sequenceImage exploreTech" alt="" />
      <img src={images['chorus_2_explore_technologies_0002.jpg']} className="sequenceImage exploreTech" alt="" />
      <img src={images['chorus_2_explore_technologies_0003.jpg']} className="sequenceImage exploreTech" alt="" />
      <img src={images['chorus_2_explore_technologies_0004.jpg']} className="sequenceImage exploreTech" alt="" />
      <img src={images['chorus_2_explore_technologies_0005.jpg']} className="sequenceImage exploreTech" alt="" />
      <img src={images['chorus_2_explore_technologies_0006.jpg']} className="sequenceImage exploreTech" alt="" />
      <img src={images['chorus_2_explore_technologies_0007.jpg']} className="sequenceImage exploreTech" alt="" />
      <img src={images['chorus_2_explore_technologies_0008.jpg']} className="sequenceImage exploreTech" alt="" />
      <img src={images['chorus_2_explore_technologies_0009.jpg']} className="sequenceImage exploreTech" alt="" />
      <img src={images['chorus_2_explore_technologies_00010.jpg']} className="sequenceImage exploreTech" alt="" />
      <img src={images['chorus_2_explore_technologies_00011.jpg']} className="sequenceImage exploreTech" alt="" />
      <img src={images['chorus_2_explore_technologies_00012.jpg']} className="sequenceImage exploreTech" alt="" />
      <img src={images['chorus_2_explore_technologies_00013.jpg']} className="sequenceImage exploreTech" alt="" />
      <img src={images['chorus_2_explore_technologies_00014.jpg']} className="sequenceImage exploreTech" alt="" />
      <img src={images['chorus_2_explore_technologies_00015.jpg']} className="sequenceImage exploreTech" alt="" />
      <img src={images['chorus_2_explore_technologies_00016.jpg']} className="sequenceImage exploreTech" alt="" />
      <img src={images['chorus_2_explore_technologies_00017.jpg']} className="sequenceImage exploreTech" alt="" />
      <img src={images['chorus_2_explore_technologies_00018.jpg']} className="sequenceImage exploreTech" alt="" />
      <img src={images['chorus_2_explore_technologies_00019.jpg']} className="sequenceImage exploreTech" alt="" />
      <img src={images['chorus_2_explore_technologies_00020.jpg']} className="sequenceImage exploreTech" alt="" />
      <img src={images['chorus_2_explore_technologies_00021.jpg']} className="sequenceImage exploreTech" alt="" />
      <img src={images['chorus_2_explore_technologies_00022.jpg']} className="sequenceImage exploreTech" alt="" />
      <img src={images['chorus_2_explore_technologies_00023.jpg']} className="sequenceImage exploreTech" alt="" />
      <img src={images['chorus_2_explore_technologies_00024.jpg']} className="sequenceImage exploreTech" alt="" />
      <img src={images['chorus_2_explore_technologies_00025.jpg']} className="sequenceImage exploreTech" alt="" />
      <img src={images['chorus_2_explore_technologies_00026.jpg']} className="sequenceImage exploreTech" alt="" />
      <img src={images['chorus_2_explore_technologies_00027.jpg']} className="sequenceImage exploreTech" alt="" />
      <img src={images['chorus_2_explore_technologies_00028.jpg']} className="sequenceImage exploreTech" alt="" />
      <img src={images['chorus_2_explore_technologies_00029.jpg']} className="sequenceImage exploreTech" alt="" />
      <img src={images['chorus_2_explore_technologies_00030.jpg']} className="sequenceImage exploreTech" alt="" />
      <img src={images['chorus_2_explore_technologies_00031.jpg']} className="sequenceImage exploreTech" alt="" />
      <img src={images['chorus_2_explore_technologies_00032.jpg']} className="sequenceImage exploreTech" alt="" />
      <img src={images['chorus_2_explore_technologies_00033.jpg']} className="sequenceImage exploreTech" alt="" />
      <img src={images['chorus_2_explore_technologies_00034.jpg']} className="sequenceImage exploreTech" alt="" />
      <img src={images['chorus_2_explore_technologies_00035.jpg']} className="sequenceImage exploreTech" alt="" />
      <img src={images['chorus_2_explore_technologies_00036.jpg']} className="sequenceImage exploreTech" alt="" />
      <img src={images['chorus_2_explore_technologies_00037.jpg']} className="sequenceImage exploreTech" alt="" />
      <img src={images['chorus_2_explore_technologies_00038.jpg']} className="sequenceImage exploreTech" alt="" />
      <img src={images['chorus_2_explore_technologies_00039.jpg']} className="sequenceImage exploreTech" alt="" />
      <img src={images['chorus_2_explore_technologies_00040.jpg']} className="sequenceImage exploreTech" alt="" />
      <img src={images['chorus_2_explore_technologies_00041.jpg']} className="sequenceImage exploreTech" alt="" />
      <img src={images['chorus_2_explore_technologies_00042.jpg']} className="sequenceImage exploreTech" alt="" />
      <img src={images['chorus_2_explore_technologies_00043.jpg']} className="sequenceImage exploreTech" alt="" />
      <img src={images['chorus_2_explore_technologies_00044.jpg']} className="sequenceImage exploreTech" alt="" />
      <img src={images['chorus_2_explore_technologies_00045.jpg']} className="sequenceImage exploreTech" alt="" />
      <img src={images['chorus_2_explore_technologies_00046.jpg']} className="sequenceImage exploreTech" alt="" />
      <img src={images['chorus_2_explore_technologies_00047.jpg']} className="sequenceImage exploreTech" alt="" />
      <img src={images['chorus_2_explore_technologies_00048.jpg']} className="sequenceImage exploreTech" alt="" />
      <img src={images['chorus_2_explore_technologies_00049.jpg']} className="sequenceImage exploreTech" alt="" />
      <img src={images['chorus_2_explore_technologies_00050.jpg']} className="sequenceImage exploreTech" alt="" />
      <img src={images['chorus_2_user_interface_0000.jpg']} className="sequenceImage userInterface" alt="" />
      <img src={images['chorus_2_user_interface_0001.jpg']} className="sequenceImage userInterface" alt="" />
      <img src={images['chorus_2_user_interface_0002.jpg']} className="sequenceImage userInterface" alt="" />
      <img src={images['chorus_2_user_interface_0003.jpg']} className="sequenceImage userInterface" alt="" />
      <img src={images['chorus_2_user_interface_0004.jpg']} className="sequenceImage userInterface" alt="" />
      <img src={images['chorus_2_user_interface_0005.jpg']} className="sequenceImage userInterface" alt="" />
      <img src={images['chorus_2_user_interface_0006.jpg']} className="sequenceImage userInterface" alt="" />
      <img src={images['chorus_2_user_interface_0007.jpg']} className="sequenceImage userInterface" alt="" />
      <img src={images['chorus_2_user_interface_0008.jpg']} className="sequenceImage userInterface" alt="" />
      <img src={images['chorus_2_user_interface_0009.jpg']} className="sequenceImage userInterface" alt="" />
      <img src={images['chorus_2_user_interface_00010.jpg']} className="sequenceImage userInterface" alt="" />
      <img src={images['chorus_2_user_interface_00011.jpg']} className="sequenceImage userInterface" alt="" />
      <img src={images['chorus_2_user_interface_00012.jpg']} className="sequenceImage userInterface" alt="" />
      <img src={images['chorus_2_user_interface_00013.jpg']} className="sequenceImage userInterface" alt="" />
      <img src={images['chorus_2_user_interface_00014.jpg']} className="sequenceImage userInterface" alt="" />
      <img src={images['chorus_2_user_interface_00015.jpg']} className="sequenceImage userInterface" alt="" />
      <img src={images['chorus_2_user_interface_00016.jpg']} className="sequenceImage userInterface" alt="" />
      <img src={images['chorus_2_user_interface_00017.jpg']} className="sequenceImage userInterface" alt="" />
      <img src={images['chorus_2_user_interface_00018.jpg']} className="sequenceImage userInterface" alt="" />
      <img src={images['chorus_2_user_interface_00019.jpg']} className="sequenceImage userInterface" alt="" />
      <img src={images['chorus_2_user_interface_00020.jpg']} className="sequenceImage userInterface" alt="" />
      <img src={images['chorus_2_user_interface_00021.jpg']} className="sequenceImage userInterface" alt="" />
      <img src={images['chorus_2_user_interface_00022.jpg']} className="sequenceImage userInterface" alt="" />
      <img src={images['chorus_2_user_interface_00023.jpg']} className="sequenceImage userInterface" alt="" />
      <img src={images['chorus_2_user_interface_00024.jpg']} className="sequenceImage userInterface" alt="" />
      <img src={images['chorus_2_user_interface_00025.jpg']} className="sequenceImage userInterface" alt="" />
      <img src={images['chorus_2_user_interface_00026.jpg']} className="sequenceImage userInterface" alt="" />
      <img src={images['chorus_2_user_interface_00027.jpg']} className="sequenceImage userInterface" alt="" />
      <img src={images['chorus_2_user_interface_00028.jpg']} className="sequenceImage userInterface" alt="" />
      <img src={images['chorus_2_user_interface_00029.jpg']} className="sequenceImage userInterface" alt="" />
      <img src={images['chorus_2_user_interface_00030.jpg']} className="sequenceImage userInterface" alt="" />
      <img src={images['chorus_2_user_interface_00031.jpg']} className="sequenceImage userInterface" alt="" />
      <img src={images['chorus_2_user_interface_00032.jpg']} className="sequenceImage userInterface" alt="" />
      <img src={images['chorus_2_user_interface_00033.jpg']} className="sequenceImage userInterface" alt="" />
      <img src={images['chorus_2_user_interface_00034.jpg']} className="sequenceImage userInterface" alt="" />
      <img src={images['chorus_2_user_interface_00035.jpg']} className="sequenceImage userInterface" alt="" />
      <img src={images['chorus_2_user_interface_00036.jpg']} className="sequenceImage userInterface" alt="" />
      <img src={images['chorus_2_user_interface_00037.jpg']} className="sequenceImage userInterface" alt="" />
      <img src={images['chorus_2_user_interface_00038.jpg']} className="sequenceImage userInterface" alt="" />
      <img src={images['chorus_2_user_interface_00039.jpg']} className="sequenceImage userInterface" alt="" />
      <img src={images['chorus_2_user_interface_00040.jpg']} className="sequenceImage userInterface" alt="" />
      <img src={images['chorus_2_user_interface_00041.jpg']} className="sequenceImage userInterface" alt="" />
      <img src={images['chorus_2_user_interface_00042.jpg']} className="sequenceImage userInterface" alt="" />
      <img src={images['chorus_2_user_interface_00043.jpg']} className="sequenceImage userInterface" alt="" />
      <img src={images['chorus_2_user_interface_00044.jpg']} className="sequenceImage userInterface" alt="" />
      <img src={images['chorus_2_user_interface_00045.jpg']} className="sequenceImage userInterface" alt="" />
      <img src={images['chorus_2_user_interface_00046.jpg']} className="sequenceImage userInterface" alt="" />
      <img src={images['chorus_2_user_interface_00047.jpg']} className="sequenceImage userInterface" alt="" />
      <img src={images['chorus_2_user_interface_00048.jpg']} className="sequenceImage userInterface" alt="" />
      <img src={images['chorus_2_user_interface_00049.jpg']} className="sequenceImage userInterface" alt="" />
      <img src={images['chorus_2_user_interface_00050.jpg']} className="sequenceImage userInterface" alt="" />
    </>
  );
}

export default Chorus2SeqImages
