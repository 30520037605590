import React from "react";
import { BrowserRouter, Route, Switch, useLocation } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { setDefaultBreakpoints, BreakpointProvider } from "react-socks";
import Home from "./components/Home";
import Applications from "./components/Applications";
import WaterType from "./components/WaterType";
import FeedType from "./components/FeedType";
import AllProducts from "./components/AllProducts";
import Products from "./components/Products";
import Product, { Modal } from "./components/Product";
import ProductView from "./components/ProductView";
import "./App.css";

import TagManager from "react-gtm-module";

const tagManagerArgs = {
  gtmId: "GTM-WFRTWHB",
};

TagManager.initialize(tagManagerArgs);

setDefaultBreakpoints([
  { xsmall: 0 },
  { small: 576 },
  { medium: 768 },
  { large: 992 },
  { xlarge: 1200 },
]);

function App() {
  return (
    <BreakpointProvider>
      <BrowserRouter>
        <ModalSwitch />
      </BrowserRouter>
    </BreakpointProvider>
  );
}
export default App;

function ModalSwitch() {
  let location = useLocation();
  let background = location.state && location.state.background;

  return (
    <TransitionGroup>
      <CSSTransition
        key={location.key}
        classNames="fade"
        timeout={1000}>
        <div className="App">
          <Switch location={background || location}>
            <Route
              exact
              path="/"
              component={Home}
            />
            <Route
              path="/allProducts"
              component={AllProducts}
            />
            <Route
              path="/applications"
              component={Applications}
            />
            <Route
              path="/waterType"
              component={WaterType}
            />
            <Route
              path="/feedType"
              component={FeedType}
            />
            <Route
              path="/products/:productName"
              component={Product}
            />
            <Route
              path="/products"
              component={Products}
            />
            <Route
              path="/interactive"
              component={ProductView}
            />
          </Switch>
          {background && (
            <Route
              path="/feature/:id"
              children={<Modal />}
            />
          )}
        </div>
      </CSSTransition>
    </TransitionGroup>
  );
}
