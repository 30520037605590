import React, { useContext } from "react";
import { Link, withRouter, useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import ProductData from "./data/ProductData";
import { ChoicesContext } from "../context/ChoicesProvider";
import { ReactComponent as Logo } from "../images/logo.svg";
import { ReactComponent as WaterTechLogo } from "../images/water-tech-logo.svg";
import DotsButton from "./DotsBtn";
import PreloadImage from "react-preload-image";

const Products = () => {
  let history = useHistory();
  const { choices } = useContext(ChoicesContext);

  let ProductsResult;
  if (choices.feedType === "Don't Know") {
    ProductsResult = ProductData.filter((x) => x.categories.includes(choices.category));
  } else {
    ProductsResult = ProductData.filter(
      (x) =>
        x.categories.includes(choices.category) &&
        x.waterTypes.includes(choices.waterType) &&
        x.feedTypes.includes(choices.feedType)
    );
  }

  function scrollRight() {
    document.getElementById("productResults").scrollLeft += 280;
  }

  function scrollLeft() {
    document.getElementById("productResults").scrollLeft -= 280;
  }

  return (
    <>
      <Link to="/">
        <Logo className="Logo" />
      </Link>
      <Link to="/">
        <WaterTechLogo className="WaterTechLogo" />
      </Link>

      <div className="pageLinks">
        <div className="breadcrumb">
          Applications > {choices.category} > {choices.waterType} > {choices.feedType}
        </div>
        <DotsButton />
      </div>

      <div className="products wrapper d-md-flex">
        <aside>
          <div className="wrapper">
            <h2 className="text-center text-md-left">
              Select a<br />
              Product
            </h2>
            <div className="scrollArrows">
              <FontAwesomeIcon
                onClick={scrollLeft}
                icon={faArrowLeft}
              />
              <FontAwesomeIcon
                onClick={scrollRight}
                icon={faArrowRight}
              />
            </div>
          </div>
        </aside>

        <main>
          {ProductsResult.length > 0 ? (
            <ResultsList />
          ) : (
            <div className="noResultsFound">
              <h3>More information is required</h3>
              <p>Please contact one of our water experts.</p>
              <Link
                className="btn"
                to={{ pathname: "https://www.veoliawatertechnologies.co.uk/campaign-forms/chorus" }}
                target="_blank"
                style={{ marginLeft: "35px", marginTop: "15px" }}>
                Request More Info
              </Link>
            </div>
          )}
        </main>

        <Link
          onClick={() => history.goBack()}
          className="backBtn">
          <FontAwesomeIcon icon={faArrowLeft} />
        </Link>
      </div>
    </>
  );
};

export default withRouter(Products);

function ResultsList() {
  const { choices, setChoices } = useContext(ChoicesContext);

  let ProductsResult;
  if (choices.feedType === "Don't Know") {
    ProductsResult = ProductData.filter((x) => x.categories.includes(choices.category));
  } else {
    ProductsResult = ProductData.filter(
      (x) =>
        x.categories.includes(choices.category) &&
        x.waterTypes.includes(choices.waterType) &&
        x.feedTypes.includes(choices.feedType)
    );
  }

  return (
    <div id="productResults">
      {ProductsResult.map((item, i) => (
        <div
          key={i}
          className="product">
          <Link
            onClick={() => setChoices({ ...choices, productSelected: item.name })}
            to={{
              pathname: "/products/" + item.slug,
              name: item.name,
            }}>
            <PreloadImage
              className="productStaticImg"
              src={item.static_img}
              lazy
            />
            <div className={`${item.colourClass} description`}>
              <h4>{item.name}</h4>
              <p>Key Features:</p>
              <ul className="features">
                {item.keyFeatures.map((item, i) => (
                  <li key={i}>{item}</li>
                ))}
              </ul>
              <p className="select">
                <FontAwesomeIcon icon={faArrowRight} /> Select
              </p>
            </div>
          </Link>
        </div>
      ))}
    </div>
  );
}
