import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome} from '@fortawesome/free-solid-svg-icons';

export default function DotsButton() {
   return (
     <Link to="/" className="dotsBtn">
      <FontAwesomeIcon icon={faHome} />
     </Link>
   )
}
