import React from "react";
import {
  Link,
  useLocation,
} from "react-router-dom";

export const AllAccessoriesData = [
  {
    id: 0,
    name: 'Ultra Filter',
    slug: 'ultra-filter',
    img: 'acc-ultra-filter.png',
    partNumbers: 'LC151',
    purpose: 'Removal of Bacteria and microbial by-products such as endotoxins',
  },
  {
    id: 1,
    name: 'Purification Pack',
    slug: 'purification-pack',
    img: 'acc-di-pack.png',
    partNumbers: 'LC232/LC244/LC245/LC246',
    purpose: 'Removal of Ions',
  },
  {
    id: 2,
    name: 'Point of Use Filter',
    slug: 'point-of-use-filter',
    img: 'acc-pou-filter.png',
    partNumbers: 'LC145/LC197',
    purpose: 'Removes particles and microorganisms. DNase / RNase',
  },
  {
    id: 3,
    name: 'UV Light',
    slug: 'uv-light',
    img: 'acc-uv-light.png',
    partNumbers: 'LC285',
    purpose: 'Reduction of TOC and destroys bacteria cells',
  },
  {
    id: 4,
    name: 'Ultra-Micron Filter',
    slug: 'ultra-micron-filter',
    img: 'acc-ultra-micron-filter.png',
    partNumbers: 'LC109',
    purpose: 'Removal of Particles and microorganisms. DNase / RNase',
  },
  {
    id: 5,
    name: 'PureSure System',
    slug: 'puresure-system',
    img: 'acc-puresure.png',
    partNumbers: '',
    purpose: 'Consistent and uncompromised delivery of 18.2 megohm quality water',
  },
  {
    id: 6,
    name: 'Pre-treatment',
    slug: 'pre-treatment',
    img: 'acc-pre-treatment.png',
    partNumbers: 'LC241',
    purpose: 'Removal of Chlorine and particulates',
  },
  {
    id: 7,
    name: 'Reverse Osmosis',
    slug: 'reverse-osmosis',
    img: 'acc-ro-pack.png',
    partNumbers: 'LC240',
    purpose: 'Removal of majority of organics and inorganics',
  },
  {
    id: 8,
    name: 'Composite Vent Filter',
    slug: 'composite-vent-filter',
    img: 'acc-comp-vent-filter.png',
    partNumbers: 'LC216',
    purpose: 'Maintains quality of stored water',
  },
  {
    id: 9,
    name: 'EDI Stack',
    slug: 'edi-stack',
    img: 'acc-edi-stack.png',
    partNumbers: 'LC277',
    purpose: 'Removal of Ions through the use of fully recirculating Electrodeionisation ensuring ionic quality',
  },
  {
    id: 10,
    name: 'Optimiser Pack',
    slug: 'optimiser-pack',
    img: 'acc-optimiser-pack.png',
    partNumbers: 'LC243',
    purpose: 'Softens feedwater to ensure efficient operation of EDI stack',
  },
];

export const Chorus1AccessoriesData = [
  {
    id: 0,
    name: 'Ultra Filter',
    slug: 'ultra-filter',
    img: 'acc-ultra-filter.png',
    partNumbers: 'LC151',
    purpose: 'Removal of Bacteria and microbial by-products such as endotoxins',
    btnClass: 'btn',
  },
  {
    id: 1,
    name: 'Purification Pack',
    slug: 'purification-pack',
    img: 'acc-di-pack.png',
    partNumbers: 'LC232/LC244/LC245/LC246',
    purpose: 'Removal of Ions',
    btnClass: 'btn-reverse',
  },
  {
    id: 2,
    name: 'Point of Use Filter',
    slug: 'point-of-use-filter',
    img: 'acc-pou-filter.png',
    partNumbers: 'LC145/LC197',
    purpose: 'Removes particles and microorganisms. DNase / RNase',
    btnClass: 'btn',
  },
  {
    id: 3,
    name: 'UV Light',
    slug: 'uv-light',
    img: 'acc-uv-light.png',
    partNumbers: 'LC210',
    purpose: 'Reduction of TOC and destroys bacteria cells',
    btnClass: 'btn-reverse',
  },
  {
    id: 4,
    name: 'Ultra-Micron Filter',
    slug: 'ultra-micron-filter',
    img: 'acc-ultra-micron-filter.png',
    partNumbers: 'LC109',
    purpose: 'Removal of Particles and microorganisms. DNase / RNase',
    btnClass: 'btn',
  },
  {
    id: 5,
    name: 'PureSure System',
    slug: 'puresure-system',
    img: 'acc-puresure.png',
    partNumbers: '',
    purpose: 'Consistent and uncompromised delivery of 18.2 megohm quality water',
    btnClass: 'btn-reverse',
  },
];

export function Chorus1Accessories() {
  let location = useLocation();

  return (
    <ul className="accessoryBtns">
      {Chorus1AccessoriesData.map((i, index) => (
        <li key={index} className={`${i.slug}`}>
          <Link
            key={i.id}
            className={`${i.btnClass}`}
            to={{
              pathname: `/accessory/${i.id}`,
              state: { background: location }
            }}
          >
            {i.name}
          </Link>
        </li>
      ))}
    </ul>
  )
}

export const Chorus1CompleteAccessoriesData = [
  {
    id: 1,
    name: 'Purification Pack',
    slug: 'purification-pack',
    img: 'acc-di-pack.png',
    partNumbers: 'LC275',
    purpose: 'Removal of Ions fully recirculates ensuring ionic quality.',
    btnClass: 'btn-reverse',
  },
  {
    id: 2,
    name: 'Point of Use Filter',
    slug: 'point-of-use-filter',
    img: 'acc-pou-filter.png',
    partNumbers: 'LC145/LC197',
    purpose: 'Removes particles and microorganisms. DNase / RNase',
    btnClass: 'btn',
  },
  {
    id: 3,
    name: 'UV Light',
    slug: 'uv-light',
    img: 'acc-uv-light.png',
    partNumbers: 'LC210',
    purpose: 'Reduction of TOC and destroys bacteria cells',
    btnClass: 'btn-reverse',
  },
  {
    id: 6,
    name: 'Pre-treatment',
    slug: 'pre-treatment',
    img: 'acc-pre-treatment.png',
    partNumbers: 'LC241',
    purpose: 'Removal of Chlorine and particulates',
    btnClass: 'btn',
  },
  {
    id: 7,
    name: 'Reverse Osmosis',
    slug: 'reverse-osmosis',
    img: 'acc-ro-pack.png',
    partNumbers: 'LC240',
    purpose: 'Removal of majority of organics and inorganics',
    btnClass: 'btn',
  }
];

export function Chorus1CompleteAccessories() {
  let location = useLocation();

  return (
    <ul className="accessoryBtns">
      {Chorus1CompleteAccessoriesData.map((i, index) => (
        <li key={index} className={`${i.slug} ${i.btnClass}`}>
          <Link
            key={i.id}
            to={{
              pathname: `/accessory/${i.id}`,
              state: { background: location }
            }}
          >
            {i.name}
          </Link>
        </li>
      ))}
    </ul>
  )
}

export const Chorus2AccessoriesData = [
  {
    id: 1,
    name: 'Purification Pack',
    slug: 'purification-pack',
    img: 'acc-di-pack.png',
    partNumbers: 'LC234',
    purpose: 'Weakly bound ions are released back into the water reducing resistivity.',
    btnClass: 'btn-reverse',
  },
  {
    id: 6,
    name: 'Pre-treatment',
    slug: 'pre-treatment',
    img: 'acc-pre-treatment.png',
    partNumbers: 'LC241',
    purpose: 'Removal of Chlorine and particulates',
    btnClass: 'btn',
  },
  {
    id: 7,
    name: 'Reverse Osmosis',
    slug: 'reverse-osmosis',
    img: 'acc-ro-pack.png',
    partNumbers: 'LC240',
    purpose: 'Removal of majority of organics and inorganics',
    btnClass: 'btn',
  },
];

export function Chorus2Accessories() {
  let location = useLocation();

  return (
    <ul className="accessoryBtns">
      {Chorus2AccessoriesData.map((i, index) => (
        <li key={index} className={`${i.slug} ${i.btnClass}`}>
          <Link
            key={i.id}
            to={{
              pathname: `/accessory/${i.id}`,
              state: { background: location }
            }}
          >
            {i.name}
          </Link>
        </li>
      ))}
    </ul>
  )
}

export const Chorus2PlusAccessoriesData = [
  {
    id: 1,
    name: 'Purification Pack',
    slug: 'purification-pack',
    img: 'acc-di-pack.png',
    partNumbers: 'LC274',
    purpose: 'Weakly bound ions are released back into the water reducing resistivity.',
    btnClass: 'btn-reverse',
  },
  {
    id: 2,
    name: 'Point of Use Filter',
    slug: 'point-of-use-filter',
    img: 'acc-pou-filter.png',
    partNumbers: 'LC145 (0.2 micron) / LC197 (Biofilter)',
    purpose: 'Removes particles and microorganisms. DNase / RNase',
    btnClass: 'btn',
  },
  {
    id: 3,
    name: 'UV Light',
    slug: 'uv-light',
    img: 'acc-uv-light.png',
    partNumbers: 'LC285',
    purpose: 'Reduction of TOC and destroys bacteria cells',
    btnClass: 'btn-reverse',
  },
  {
    id: 6,
    name: 'Pre-treatment',
    slug: 'pre-treatment',
    img: 'acc-pre-treatment.png',
    partNumbers: 'LC241',
    purpose: 'Removal of Chlorine and particulates',
    btnClass: 'btn',
  },
  {
    id: 7,
    name: 'Reverse Osmosis',
    slug: 'reverse-osmosis',
    img: 'acc-ro-pack.png',
    partNumbers: 'LC240',
    purpose: 'Removal of majority of organics and inorganics',
    btnClass: 'btn',
  },
  {
    id: 8,
    name: 'Composite Vent Filter',
    slug: 'composite-vent-filter',
    img: 'acc-comp-vent-filter.png',
    partNumbers: 'LC216',
    purpose: 'Maintains quality of stored water',
    btnClass: 'btn-reverse',
  },
  {
    id: 9,
    name: 'EDI Stack',
    slug: 'edi-stack',
    img: 'acc-edi-stack.png',
    partNumbers: 'LC277',
    purpose: 'Removal of Ions through the use of fully recirculating Electrodeionisation ensuring ionic quality',
    btnClass: 'btn',
  },
  {
    id: 10,
    name: 'Optimiser Pack',
    slug: 'optimiser-pack',
    img: 'acc-optimiser-pack.png',
    partNumbers: 'LC243',
    purpose: 'Softens feedwater to ensure efficient operation of EDI stack',
    btnClass: 'btn-reverse',
  },
];

export function Chorus2PlusAccessories() {
  let location = useLocation();

  return (
    <ul className="accessoryBtns">
      {Chorus2PlusAccessoriesData.map((i, index) => (
        <li key={index} className={`${i.slug} ${i.btnClass}`}>
          <Link
            key={i.id}
            to={{
              pathname: `/accessory/${i.id}`,
              state: { background: location }
            }}
          >
            {i.name}
          </Link>
        </li>
      ))}
    </ul>
  )
}

export const Chorus3AccessoriesData = [
  {
    id: 6,
    name: 'Pre-treatment',
    slug: 'pre-treatment',
    img: 'acc-pre-treatment.png',
    partNumbers: 'LC241',
    purpose: 'Removal of Chlorine and particulates',
    btnClass: 'btn-reverse',
  },
  {
    id: 7,
    name: 'Reverse Osmosis',
    slug: 'reverse-osmosis',
    img: 'acc-ro-pack.png',
    partNumbers: 'LC240',
    purpose: 'Removal of majority of organics and inorganics',
    btnClass: 'btn',
  },
];

export function Chorus3Accessories() {
  let location = useLocation();

  return (
    <ul className="accessoryBtns">
      {Chorus3AccessoriesData.map((i, index) => (
        <li key={index} className={`${i.slug} ${i.btnClass}`}>
          <Link
            key={i.id}
            to={{
              pathname: `/accessory/${i.id}`,
              state: { background: location }
            }}
          >
            {i.name}
          </Link>
        </li>
      ))}
    </ul>
  )
}
