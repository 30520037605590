import React, {useContext} from "react";
import {
  Link,
  withRouter
} from "react-router-dom";
import { ReactComponent as Logo } from '../images/logo.svg';
import { ReactComponent as WaterTechLogo } from '../images/water-tech-logo.svg';
import { ReactComponent as Icon } from '../images/icon.svg';
import { ChoicesContext } from "../context/ChoicesProvider";

const Home = () => {
  const { choices, setChoices } = useContext(ChoicesContext);

  return (
    <div className="homebg">
      <Logo className="Logo" />
      <WaterTechLogo className="WaterTechLogo" />

      <div className="home wrapper text-center">
        <div className="row">
          <div className="col-lg-6">
            <Icon className="Icon" />
            <h2 className="text-uppercase"><strong>Purelab&reg;</strong></h2>
            <p className="h2">Innovation and Flexibility</p>
            <Link
              to={{
                pathname: "/applications"
              }}
              className="btn"
            >
              Start
            </Link>
          </div>
        </div>

        <Link
          onClick={() => setChoices({ ...choices, category: null, waterType: null, feedType: null, productSelected: null })}
          to={{
            pathname: "/allProducts"
          }}
          className="btn viewAllProducts"
        >
          View All Products
        </Link>
      </div>
    </div>
  );
};

export default withRouter(Home);
