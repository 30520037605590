import React, {useContext} from "react";
import {
  Link,
  withRouter,
  useHistory
} from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import ProductData from "./data/ProductData";
import { ChoicesContext } from "../context/ChoicesProvider";
import { ReactComponent as Logo } from '../images/logo.svg';
import { ReactComponent as WaterTechLogo } from '../images/water-tech-logo.svg';
import DotsButton from './DotsBtn';
import PreloadImage from 'react-preload-image';

const AllProducts = () => {
  let history = useHistory();

  function scrollRight() {
  	document.getElementById('productResults').scrollLeft += 280;
	}

  function scrollLeft() {
  	document.getElementById('productResults').scrollLeft -= 280;
	}

  return (
    <>
      <Link to="/">
        <Logo className="Logo" />
      </Link>
      <Link to="/">
	      <WaterTechLogo className="WaterTechLogo" />
      </Link>

      <div className="pageLinks">
        <div className="breadcrumb">All Products</div>
        <DotsButton />
      </div>

      <div className="products wrapper d-md-flex">

        <aside>
          <div className="wrapper">
            <h2 className="text-center text-md-left">Select a<br />Product</h2>
            <div className="scrollArrows">
              <FontAwesomeIcon onClick={scrollLeft} icon={faArrowLeft} />
              <FontAwesomeIcon onClick={scrollRight} icon={faArrowRight} />
            </div>
          </div>
        </aside>

        <main>
          <ProductsList />
        </main>

        <Link onClick={() => history.goBack()} className="backBtn"><FontAwesomeIcon icon={faArrowLeft} /></Link>
      </div>
    </>
  );
};

export default withRouter(AllProducts);

function ProductsList() {
  const { choices, setChoices } = useContext(ChoicesContext);

  return (
    <div id="productResults">
      {ProductData.map((item, i) => (
        <div key={i} className="product">
          <Link
            onClick={() => setChoices({ ...choices, productSelected: item.name })}
            to={{
              pathname: '/products/' + item.slug,
              name: item.name,
            }}
          >
          <PreloadImage
            className="productStaticImg"
            src={item.static_img}
            lazy
          />
          <div className={`${item.colourClass} description`}>
            <h4>{item.name}</h4>
            <p>Key Features:</p>
            <ul className="features">
              {item.keyFeatures.map((item, i) => (
                <li key={i}>{item}</li>
              ))}
            </ul>
            <p className="select"><FontAwesomeIcon icon={faArrowRight} /> Select</p>
          </div>
          </Link>
        </div>
      ))}
    </div>
  )
}
