import React, {useContext} from "react";
import {
  Link,
  withRouter,
  useHistory
} from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import FeedTypeData from "./data/FeedTypeData";
import { ChoicesContext } from "../context/ChoicesProvider";
import { ReactComponent as Logo } from '../images/logo.svg';
import { ReactComponent as WaterTechLogo } from '../images/water-tech-logo.svg';
import DotsButton from './DotsBtn';

const FeedType = () => {
  let history = useHistory();
  const { choices, setChoices } = useContext(ChoicesContext);

  return (
    <>
      <Link to="/">
        <Logo className="Logo" />
      </Link>
      <Link to="/">
	      <WaterTechLogo className="WaterTechLogo" />
      </Link>

      <div className="pageLinks">
        <div className="breadcrumb">Applications > {choices.category} > {choices.waterType}</div>
        <DotsButton />
      </div>

      <div className="waterTypes wrapper d-md-flex">
        <aside>
        <h2>Types of <br/>Feed</h2>
        </aside>

        <main>
          <h3>What type of feed do you have?</h3>
          <div className="boxLinks">
            <div className="row">
              {FeedTypeData.map((type, i) => (
                <div key={i} className="col-md-6 col-xl-4 boxLink">
                  <div className={`${type.colourClass} wrapper`}>
                    <Link
                      onClick={() => setChoices({ ...choices, feedType: type.name })}
                      to={{
                        pathname: "/products",
                      }}
                    >
                      <h4>{type.name}</h4>
                      <p>{type.description}</p>
                      <p className="select"><FontAwesomeIcon icon={faArrowRight} /> Select</p>
                    </Link>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </main>

        <Link onClick={() => history.goBack()} className="backBtn"><FontAwesomeIcon icon={faArrowLeft} /></Link>
      </div>
    </>
  );
};

export default withRouter(FeedType);
