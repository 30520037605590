// ChoicesProvider.js
import React, { createContext, useState } from "react";

export const ChoicesContext = createContext(null);

export const ChoicesProvider = ({ children }) => {
  const [choices, setChoices] = useState({
    category: null,
    waterType: null,
    feedType: null,
    productSelected: null
  });

  return (
    <ChoicesContext.Provider value={{ choices, setChoices }}>
      {children}
    </ChoicesContext.Provider>
  );
};
