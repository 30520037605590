import React, { useContext } from "react";
import {
  Link,
  useHistory,
  useLocation,
  useParams,
  withRouter
} from "react-router-dom";
import '../App.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import ProductData from "./data/ProductData";
import { ReactComponent as Logo } from '../images/logo.svg';
import { ReactComponent as WaterTechLogo } from '../images/water-tech-logo.svg';
import { ChoicesContext } from "../context/ChoicesProvider";
import DotsButton from './DotsBtn';
import accPuresure from '../images/accessories/acc-puresure.png';
import toc from '../images/accessories/toc.jpg';

function Product() {
  let history = useHistory();
  const { choices } = useContext(ChoicesContext);
  const CurrentProduct = ProductData.filter(x => x.name === choices.productSelected);

  return (
    <>
      <Link to="/">
        <Logo className="Logo" />
      </Link>
      <Link to="/">
	      <WaterTechLogo className="WaterTechLogo" />
      </Link>

      <div className="pageLinks">
        <div className="breadcrumb">Purelab {choices.productSelected} > Process Flow</div>
        <DotsButton />
      </div>

      <div className="product wrapper d-md-flex">
        <aside>
          <h1>PURELAB<br />{choices.productSelected}</h1>
        </aside>

        <main>

          {CurrentProduct.map((item, i) => (
            <div key={i} className={item.featureBtnClass}>
              <h3>{item.process_flow_title}</h3>

              <video autoPlay>
                <source
                  type="video/mp4"
                  src={item.process_flow_vimeo} />
              </video>
              <ProductFeaturesButtons />
            </div>
          ))}

          <Link
            to={{pathname: "/interactive",}}
            className="btn viewProduct"
          >Next</Link>

        </main>

        <Link onClick={() => history.goBack()} className="backBtn"><FontAwesomeIcon icon={faArrowLeft} /></Link>
      </div>
    </>
  )
}
export default withRouter(Product);

const ProductFeatures = [
  {
    id: 0,
    img: toc,
    buttonTitle: 'TOC',
    title: "Real time TOC measurement",
    description: "By measuring the resistivity of the purified water before and after UV we can calculate the difference and therefore the TOC concentration, this is continuous and therefore real time and not delayed - (See Technology note 15 for more information)",
    pdfLink: 'https://campaign.veoliawatertechnologies.co.uk/hubfs/science-app/P015.5%20TN%2018%20PURELAB%20Flex%20Real%20Time%20TOC%20System_FINAL.pdf'
  },
  {
    id: 1,
    img: accPuresure,
    buttonTitle: 'PureSure®',
    title: "PureSure® Technology used in PURELAB® Chorus 1",
    description: "The PURELAB Chorus features a unique double purification pack and monitoring system which provides key benefits to the user:",
    pdfLink: 'https://campaign.veoliawatertechnologies.co.uk/hubfs/science-app/P015.6%20TN%2025%20PureSure%20Technology%20used%20in%20PURELAB%20Chorus%201_FINAL%20(1).pdf',
    listOne: 'Guaranteed water purity',
    listTwo: 'No break-through of organics, silicon or boron',
    listThree: 'Increased security',
    listFour: 'Capacity gains'
  },
];

function ProductFeaturesButtons() {
  let location = useLocation();

  return (
    <ul className="featureBtns">
      {ProductFeatures.map((i, index) => (
        <li key={index}>
          <Link
            key={i.id}
            className="btn-reverse infoBtn"
            to={{
              pathname: `/feature/${i.id}`,
              state: { background: location }
            }}
          >
            {i.buttonTitle}
          </Link>
        </li>
      ))}
    </ul>
  );
}

export function Modal() {
  let history = useHistory();
  let { id } = useParams();
  let feature = ProductFeatures[parseInt(id, 10)];

  if (!feature) return null;

  let back = e => {
    e.stopPropagation();
    history.goBack();
  };

  return (
    <div className="modal">
      <div className="row align-items-center">
        <div className="col-md-6 text-right">
        	<img src={feature.img} alt={feature.title} width="400" />
        </div>
        <div className="col-md-6">
          <h2>{feature.title}</h2>
          <p>{feature.description}</p>
          {feature.id === 1 ?
          <ul>
	          <li>{feature.listOne}</li>
	          <li>{feature.listTwo}</li>
	          <li>{feature.listThree}</li>
	          <li>{feature.listFour}</li>
	        </ul>
          : null }
          <a
            href={feature.pdfLink}
            className="btn"
            style={{marginLeft: '35px', marginTop: '20px'}}
            target="_blank"
            rel="noopener noreferrer"
          >View more info</a>
        </div>
      </div>
      <div className="backBtn" onClick={back}><FontAwesomeIcon icon={faArrowLeft} /></div>
    </div>
  );
}
