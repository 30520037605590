import React from "react";

const Chorus1UIFeaturesData = [
  {
    name: 'Display of TOC',
    slug: 'display-of-toc',
    btnClass: 'feature'
  },
  {
    name: 'Infobar',
    slug: 'infobar',
    btnClass: 'feature reverse'
  },
];

export function Chorus1UIFeatures() {
  return (
    <ul className="featuresList">
      {Chorus1UIFeaturesData.map((i, index) => (
        <li key={index} className={`${i.slug} ${i.btnClass}`}>
          {i.name}
        </li>
      ))}
    </ul>
  )
}

const Chorus1CompleteUIFeaturesData = [
  {
    name: 'Infobar',
    slug: 'infobar',
    btnClass: 'feature reverse'
  },
  {
    name: 'Quality Display',
    slug: 'quality-display',
    btnClass: 'feature'
  },
  {
    name: 'Accept Button',
    slug: 'accept-button',
    btnClass: 'feature'
  },
  {
    name: 'Reservoir Level',
    slug: 'reservoir-level',
    btnClass: 'feature reverse'
  },
];

export function Chorus1CompleteUIFeatures() {
  return (
    <ul className="featuresList">
      {Chorus1CompleteUIFeaturesData.map((i, index) => (
        <li key={index} className={`${i.slug} ${i.btnClass}`}>
          {i.name}
        </li>
      ))}
    </ul>
  )
}

const Chorus2UIFeaturesData = [
  {
    name: 'Infobar',
    slug: 'infobar',
    btnClass: 'feature reverse'
  },
  {
    name: 'Quality Display',
    slug: 'quality-display',
    btnClass: 'feature'
  },
  {
    name: 'Accept Button',
    slug: 'accept-button',
    btnClass: 'feature'
  },
  {
    name: 'Reservoir Level',
    slug: 'reservoir-level',
    btnClass: 'feature reverse'
  },
];

export function Chorus2UIFeatures() {
  return (
    <ul className="featuresList">
      {Chorus2UIFeaturesData.map((i, index) => (
        <li key={index} className={`${i.slug} ${i.btnClass}`}>
          {i.name}
        </li>
      ))}
    </ul>
  )
}

const Chorus2PlusUIFeaturesData = [
  {
    name: 'Infobar',
    slug: 'infobar',
    btnClass: 'feature reverse'
  },
  {
    name: 'Quality Display',
    slug: 'quality-display',
    btnClass: 'feature'
  },
  {
    name: 'Accept Button',
    slug: 'accept-button',
    btnClass: 'feature'
  },
  {
    name: 'Reservoir Level',
    slug: 'reservoir-level',
    btnClass: 'feature reverse'
  },
];

export function Chorus2PlusUIFeatures() {
  return (
    <ul className="featuresList">
      {Chorus2PlusUIFeaturesData.map((i, index) => (
        <li key={index} className={`${i.slug} ${i.btnClass}`}>
          {i.name}
        </li>
      ))}
    </ul>
  )
}

const Chorus3UIFeaturesData = [
  {
    name: 'Infobar',
    slug: 'infobar',
    btnClass: 'feature reverse'
  },
  {
    name: 'Quality Display',
    slug: 'quality-display',
    btnClass: 'feature'
  },
  {
    name: 'Reservoir Level',
    slug: 'reservoir-level',
    btnClass: 'feature reverse'
  },
];

export function Chorus3UIFeatures() {
  return (
    <ul className="featuresList">
      {Chorus3UIFeaturesData.map((i, index) => (
        <li key={index} className={`${i.slug} ${i.btnClass}`}>
          {i.name}
        </li>
      ))}
    </ul>
  )
}
