import React, { useContext } from "react";
import {
  Link,
  withRouter
} from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import ApplicationData from "./data/ApplicationData";
import { ChoicesContext } from "../context/ChoicesProvider";
import { ReactComponent as Logo } from '../images/logo.svg';
import { ReactComponent as WaterTechLogo } from '../images/water-tech-logo.svg';
import DotsButton from './DotsBtn';

class Applications extends React.Component {

  constructor(props) {
    super(props);

    this.handleScrollRight = this.handleScrollRight.bind(this);
    this.handleScrollLeft = this.handleScrollLeft.bind(this);
  }

  componentDidMount() {
    const slider = document.getElementById("applicationsList");
    let isDown = false;
    let startX;
    let scrollLeft;

    slider.addEventListener('mousedown', (e) => {
      isDown = true;
      slider.classList.add('active');
      startX = e.pageX - slider.offsetLeft;
      scrollLeft = slider.scrollLeft;
    });

    slider.addEventListener('mouseleave', () => {
      isDown = false;
      slider.classList.remove('active');
    });

    slider.addEventListener('mouseup', () => {
      isDown = false;
      slider.classList.remove('active');
    });

    slider.addEventListener('mousemove', (e) => {
      if(!isDown) return;
      e.preventDefault();
      const x = e.pageX - slider.offsetLeft;
      const walk = (x - startX) * 3; //scroll-fast
      slider.scrollLeft = scrollLeft - walk;
      console.log(walk);
    });
  }

  handleScrollRight = () => document.getElementById('applicationsList').scrollLeft += 280;
  handleScrollLeft = () => document.getElementById('applicationsList').scrollLeft -= 280;

  render() {

    return (
      <>
        <Link to="/">
          <Logo className="Logo" />
        </Link>
        <Link to="/">
  	      <WaterTechLogo className="WaterTechLogo" />
        </Link>

        <div className="pageLinks">
          <div className="breadcrumb">Applications</div>
          <DotsButton />
        </div>

        <ViewAllProducts />

        <div className="applications wrapper d-md-flex">
          <aside>
            <div className="wrapper">
              <h2 className="text-center text-md-left">Select an<br />Application</h2>
              <div className="scrollArrows">
                <FontAwesomeIcon onClick={this.handleScrollLeft} icon={faArrowLeft} />
                <FontAwesomeIcon onClick={this.handleScrollRight} icon={faArrowRight} />
              </div>
            </div>
          </aside>

          <main>
            <ApplicationsList />
          </main>
        </div>
      </>
    );
  }
}

export default withRouter(Applications);

function ViewAllProducts() {
  const { choices, setChoices } = useContext(ChoicesContext);

  return (
    <Link
      onClick={() => setChoices({ ...choices, category: null, waterType: null, feedType: null, productSelected: null })}
      to={{
        pathname: "/allProducts"
      }}
      className="btn viewAllProducts"
    >
      View All Products
    </Link>
  );
}

function ApplicationsList() {
  const { choices, setChoices } = useContext(ChoicesContext);

  return(
    <div id="applicationsList">
      {ApplicationData.map((cat, i) => (
        <div key={i} className="application">
          <Link
            onClick={() => setChoices({ ...choices, category: cat.name })}
            to={{
              pathname: "/waterType",
              name: cat.name,
            }}
          >
            <div className="application-img">
              <img src={require(`../images/applications/${cat.img}`)} alt={cat.name} />
            </div>
            <h4 className={`${cat.colourClass} appTitle`}>{cat.name}</h4>
          </Link>
        </div>
      ))}
    </div>
  );
}
