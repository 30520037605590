const WaterTypeData = [
  {
    name: 'Type 1',
    description: 'Ultra pure water for water-sensitive applications such as molecular biology and gas chromatography.',
    colourClass: 'bgPurple'
  },
  {
    name: 'Type 2',
    description: 'Pure water for less sensitive applications such as histology and stability tests.',
    colourClass: 'bgTurquoise'
  },
  {
    name: 'Type 3',
    description: 'General grade water for autoclaves, hydroponics and stability chambers, glass washers to name a few.',
    colourClass: 'bgGreen'
  },
];

export default WaterTypeData
