const ApplicationData = [
  {
    name: 'Atomic Spectroscopy',
    img: 'atomic-spectroscopy.jpg',
    colourClass: 'bgPurple',
    waterTypes: ['Type 1']
  },
  {
    name: 'Cell Cultures',
    img: 'cell-cultures.jpg',
    colourClass: 'bgOrange',
    waterTypes: ['Type 1', 'Type 2']
  },
  {
    name: 'DNA/RNA Work',
    img: 'atomic-spectroscopy.jpg',
    colourClass: 'bgYellow',
    waterTypes: ['Type 1']
  },
  {
    name: 'Electrochemistry',
    img: 'electrochemistry.jpg',
    colourClass: 'bgGreen',
    waterTypes: ['Type 1']
  },
  {
    name: 'Electrophoresis',
    img: 'atomic-spectroscopy.jpg',
    colourClass: 'bgLightPurple',
    waterTypes: ['Type 1']
  },
  {
    name: 'Endotoxin Analysis',
    img: 'atomic-spectroscopy.jpg',
    colourClass: 'bgTurquoise',
    waterTypes: ['Type 1']
  },
  {
    name: 'Gas Chromatography',
    img: 'gas-chromatography.jpg',
    colourClass: 'bgBlue',
    waterTypes: ['Type 1']
  },
  {
    name: 'GC,MS, GF-AAS',
    img: 'atomic-spectroscopy.jpg',
    colourClass: 'bgPurple',
    waterTypes: ['Type 1']
  },
  {
    name: 'General Chemistry',
    img: 'general-chemistry.jpg',
    colourClass: 'bgRed',
    waterTypes: ['Type 1', 'Type 2', 'Type 3']
  },
  {
    name: 'General Lab Water',
    img: 'general-lab.jpg',
    colourClass: 'bgGreen',
    waterTypes: ['Type 1', 'Type 2', 'Type 3']
  },
  {
    name: 'Genetic',
    img: 'genetic.jpg',
    colourClass: 'bgYellow',
    waterTypes: ['Type 1']
  },
  {
    name: 'Glass Ware Washing',
    img: 'genetic.jpg',
    colourClass: 'bgBlue',
    waterTypes: ['Type 2', 'Type 3']
  },
  {
    name: 'HPLC',
    img: 'atomic-spectroscopy.jpg',
    colourClass: 'bgBlue',
    waterTypes: ['Type 1', 'Type 2']
  },
  {
    name: 'Hydroponics',
    img: 'atomic-spectroscopy.jpg',
    colourClass: 'bgMint',
    waterTypes: ['Type 2', 'Type 3']
  },
  {
    name: 'Immunochemistry',
    img: 'immunochemistry.jpg',
    colourClass: 'bgLightPurple',
    waterTypes: ['Type 1']
  },
  {
    name: 'Life Science',
    img: 'atomic-spectroscopy.jpg',
    colourClass: 'bgOrange',
    waterTypes: ['Type 1', 'Type 2']
  },
  {
    name: 'Liquid Chromatography',
    img: 'liquid-chromatography.jpg',
    colourClass: 'bgRed',
    waterTypes: ['Type 1']
  },
  {
    name: 'Mammalian Cell Culture',
    img: 'atomic-spectroscopy.jpg',
    colourClass: 'bgTurquoise',
    waterTypes: ['Type 1']
  },
  {
    name: 'Mass Spectrometry',
    img: 'mass-spectromy.jpg',
    colourClass: 'bgLightPurple',
    waterTypes: ['Type 1']
  },
  {
    name: 'Media Preparation',
    img: 'atomic-spectroscopy.jpg',
    colourClass: 'bgRed',
    waterTypes: ['Type 2', 'Type 3']
  },
  {
    name: 'Microbiological Analysis',
    img: 'microbiological-analysis.jpg',
    colourClass: 'bgBlue',
    waterTypes: ['Type 1', 'Type 2']
  },
  {
    name: 'Molecular Biology',
    img: 'atomic-spectroscopy.jpg',
    colourClass: 'bgGreen',
    waterTypes: ['Type 1']
  },
  {
    name: 'Steam Generation',
    img: 'atomic-spectroscopy.jpg',
    colourClass: 'bgYellow',
    waterTypes: ['Type 2', 'Type 3']
  },
  {
    name: 'Spectrophotometry',
    img: 'spectophotometry.jpg',
    colourClass: 'bgRed',
    waterTypes: ['Type 1']
  },
  {
    name: 'Tissue Culture',
    img: 'atomic-spectroscopy.jpg',
    colourClass: 'bgPurple',
    waterTypes: ['Type 1']
  },
  {
    name: 'TOC',
    img: 'atomic-spectroscopy.jpg',
    colourClass: 'bgYellow',
    waterTypes: ['Type 1']
  },
];

export default ApplicationData
