import React, { useContext } from "react";
import { Link, withRouter } from "react-router-dom";
import "../App.scss";
import ProductData from "./data/ProductData";
import {
  Chorus1AccessoriesData,
  Chorus1CompleteAccessoriesData,
  Chorus2AccessoriesData,
  Chorus2PlusAccessoriesData,
  Chorus3AccessoriesData,
} from "./data/AccessoriesData";
import {
  Chorus1UIFeatures,
  Chorus1CompleteUIFeatures,
  Chorus2UIFeatures,
  Chorus2PlusUIFeatures,
  Chorus3UIFeatures,
} from "./data/UIFeaturesData";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { ReactComponent as Logo } from "../images/logo.svg";
import { ReactComponent as RequestInfoBtn } from "../images/request-info-element.svg";
import { ReactComponent as WaterTechLogo } from "../images/water-tech-logo.svg";
import { ChoicesContext } from "../context/ChoicesProvider";
import { Breakpoint } from "react-socks";
import DotsButton from "./DotsBtn";

import Chorus1SeqImages from "./imageseq/Chorus1SeqImages";
import Chorus1CompleteSeqImages from "./imageseq/Chorus1CompleteSeqImages";
import Chorus2SeqImages from "./imageseq/Chorus2SeqImages";
import Chorus2PlusSeqImages from "./imageseq/Chorus2PlusSeqImages";
import Chorus3SeqImages from "./imageseq/Chorus3SeqImages";

class ProductView extends React.Component {
  static contextType = ChoicesContext;

  constructor(props) {
    super(props);

    this.state = {
      seq: null,
      seqOpen: null,
      open: false,
      selectedAccessory: null,
    };

    this.startImageSequence = this.startImageSequence.bind(this);
    this.reverseImageSequence = this.reverseImageSequence.bind(this);
  }

  startImageSequence(id) {
    let sequence_current_frame = 0;
    let seqimgs = document.querySelectorAll(`${id === "et" ? ".exploreTech" : ".userInterface"}`);
    let sequence_frames = seqimgs.length - 1;

    let intervalHandle = setInterval(function () {
      seqimgs[sequence_current_frame].style.opacity = 0;

      sequence_current_frame++;

      if (sequence_current_frame >= sequence_frames) {
        clearInterval(intervalHandle);
        seqimgs[sequence_frames].style.opacity = 1;
      } else {
        seqimgs[sequence_current_frame].style.opacity = 1;
      }
    }, 50);

    this.setState({
      seqOpen: !this.state.seqOpen,
      seq: id,
    });
  }

  reverseImageSequence(id) {
    let seqimgs = document.querySelectorAll(`${id === "et" ? ".exploreTech" : ".userInterface"}`);
    let sequence_current_frame = seqimgs.length - 1;

    let intervalHandle = setInterval(function () {
      seqimgs[sequence_current_frame].style.opacity = 0;

      sequence_current_frame--;

      if (sequence_current_frame <= 0) {
        clearInterval(intervalHandle);
      } else {
        seqimgs[sequence_current_frame].style.opacity = 1;
      }
    }, 50);

    this.setState({
      seqOpen: !this.state.seqOpen,
      seq: null,
    });
  }

  onOpenModal = (i) => {
    this.setState({
      open: true,
      selectedAccessory: i, // When a post is clicked, mark it as selected
    });
  };

  onCloseModal = () => {
    this.setState({ open: false, selectedAccessoryIndex: null });
  };

  renderPosts = () => {
    const { choices } = this.context;

    if (choices.productSelected === "Chorus 1") {
      return (
        <ul className="accessoryBtns">
          {Chorus1AccessoriesData.map((accessory, i) => (
            <li
              key={accessory.id}
              className={`${accessory.slug} ${accessory.btnClass}`}
              onClick={() => this.onOpenModal(i)}>
              {accessory.name}
            </li>
          ))}
        </ul>
      );
    } else if (choices.productSelected === "Chorus 1 Complete") {
      return (
        <ul className="accessoryBtns">
          {Chorus1CompleteAccessoriesData.map((accessory, i) => (
            <li
              key={accessory.id}
              className={`${accessory.slug} ${accessory.btnClass}`}
              onClick={() => this.onOpenModal(i)}>
              {accessory.name}
            </li>
          ))}
        </ul>
      );
    } else if (choices.productSelected === "Chorus 2") {
      return (
        <ul className="accessoryBtns">
          {Chorus2AccessoriesData.map((accessory, i) => (
            <li
              key={accessory.id}
              className={`${accessory.slug} ${accessory.btnClass}`}
              onClick={() => this.onOpenModal(i)}>
              {accessory.name}
            </li>
          ))}
        </ul>
      );
    } else if (choices.productSelected === "Chorus 2+") {
      return (
        <ul className="accessoryBtns">
          {Chorus2PlusAccessoriesData.map((accessory, i) => (
            <li
              key={accessory.id}
              className={`${accessory.slug} ${accessory.btnClass}`}
              onClick={() => this.onOpenModal(i)}>
              {accessory.name}
            </li>
          ))}
        </ul>
      );
    } else if (choices.productSelected === "Chorus 3") {
      return (
        <ul className="accessoryBtns">
          {Chorus3AccessoriesData.map((accessory, i) => (
            <li
              key={accessory.id}
              className={`${accessory.slug} ${accessory.btnClass}`}
              onClick={() => this.onOpenModal(i)}>
              {accessory.name}
            </li>
          ))}
        </ul>
      );
    }
  };

  renderModal = () => {
    // Check to see if there's a selected post. If so, render it.
    if (this.state.selectedAccessory !== null) {
      const { choices } = this.context;
      let accessory;

      if (choices.productSelected === "Chorus 1") {
        accessory = Chorus1AccessoriesData[this.state.selectedAccessory];
      } else if (choices.productSelected === "Chorus 1 Complete") {
        accessory = Chorus1CompleteAccessoriesData[this.state.selectedAccessory];
      } else if (choices.productSelected === "Chorus 2") {
        accessory = Chorus2AccessoriesData[this.state.selectedAccessory];
      } else if (choices.productSelected === "Chorus 2+") {
        accessory = Chorus2PlusAccessoriesData[this.state.selectedAccessory];
      } else if (choices.productSelected === "Chorus 3") {
        accessory = Chorus3AccessoriesData[this.state.selectedAccessory];
      }

      return (
        <>
          <div className="row">
            <div className="col-md-6 text-right accessory-img">
              <img
                src={require(`../images/accessories/${accessory.img}`)}
                alt={accessory.name}
              />
            </div>
            <div className="col-md-6 d-md-flex align-items-center">
              <div className="wrapper">
                <h2>{accessory.name}</h2>
                <p>Part No. {accessory.partNumbers}</p>
                <p>
                  <strong>Purpose of Technologies</strong>
                  <br />
                  {accessory.purpose}
                </p>
                <Link
                  to={{
                    pathname: "https://www.veoliawatertechnologies.co.uk/campaign-forms/chorus",
                  }}
                  target="_blank"
                  className="btn request-more-info">
                  Request Info
                </Link>
              </div>
            </div>
          </div>
          <div
            onClick={() => this.onCloseModal()}
            className="backBtn">
            <FontAwesomeIcon icon={faArrowLeft} />
          </div>
        </>
      );
    }
  };

  configNotice() {
    const { choices } = this.context;
    const noticeClasses = this.state.seqOpen ? "config-notice" : "config-notice hide";

    if (choices.productSelected === "Chorus 1") {
      return <div className={noticeClasses}>Configuration based on application</div>;
    } else if (choices.productSelected === "Chorus 2+") {
      return (
        <div className={noticeClasses}>Technology configuration based on feedwater quality</div>
      );
    }
  }

  backBtn() {
    if (this.state.seqOpen && this.state.seq === "ui") {
      return (
        <div
          className="backBtn"
          onClick={() => this.reverseImageSequence("ui")}>
          <FontAwesomeIcon icon={faArrowLeft} />
        </div>
      );
    } else if (this.state.seqOpen && this.state.seq === "et") {
      return (
        <div
          className="backBtn"
          onClick={() => this.reverseImageSequence("et")}>
          <FontAwesomeIcon icon={faArrowLeft} />
        </div>
      );
    } else {
      return (
        <div
          className="backBtn"
          onClick={this.props.history.goBack}>
          <FontAwesomeIcon icon={faArrowLeft} />
        </div>
      );
    }
  }

  requestInfoBtn() {
    if (this.state.seqOpen) {
      return (
        <Link
          to={{ pathname: "https://www.veoliawatertechnologies.co.uk/campaign-forms/chorus" }}
          target="_blank"
          className="btn request-more-info">
          Request Info
        </Link>
      );
    }
  }

  render() {
    const { choices } = this.context;
    const CurrentProduct = ProductData.filter((x) => x.name === choices.productSelected);

    const { open } = this.state;

    const accessoriesClasses =
      this.state.seqOpen && this.state.seq === "et" ? "accessories" : "accessories hide";
    const uiFeaturesClasses =
      this.state.seqOpen && this.state.seq === "ui" ? "uiFeatures" : "uiFeatures hide";
    const featuresClasses = this.state.seqOpen ? "features hide" : "features";

    return (
      <>
        <Link to="/">
          <Logo className="Logo" />
        </Link>
        <Link to="/">
          <WaterTechLogo className="WaterTechLogo" />
        </Link>

        <div className="pageLinks">
          <div className="breadcrumb">
            Purelab {choices.productSelected} {">"} Front
          </div>
          <DotsButton />
        </div>

        <div className="productInteractive wrapper">
          {CurrentProduct.map((item, i) => (
            <main
              key={i}
              className={item.slug}>
              <div className="imageSequence">
                <img
                  src={item.static_img}
                  alt=""
                  className="staticImage"
                />

                <Breakpoint
                  medium
                  up>
                  <SequenceImages />
                </Breakpoint>

                <div className={featuresClasses}>
                  <button
                    onClick={() => this.startImageSequence("et")}
                    className={`${item.etBtnClass} featureBtn exploreTechnologiesBtn`}>
                    Front Doors
                  </button>
                  <button
                    onClick={() => this.startImageSequence("ui")}
                    className={`${item.uiBtnClass} featureBtn userInterfaceBtn`}>
                    User Interface
                  </button>
                </div>

                <div className={accessoriesClasses}>
                  <h4 className="text-center">Accessories</h4>
                  {this.renderPosts()}
                </div>

                <Modal
                  open={open}
                  onClose={this.onCloseModal}
                  center>
                  {this.renderModal()}
                </Modal>

                <div className={uiFeaturesClasses}>
                  <UIFeatures />
                </div>

                {this.configNotice()}
              </div>
            </main>
          ))}

          <Link
            to={{ pathname: "https://www.veoliawatertechnologies.co.uk/campaign-forms/chorus" }}
            target="_blank">
            <RequestInfoBtn className="RequestInfoBtn" />
          </Link>
          {this.backBtn()}
        </div>
      </>
    );
  }
}
export default withRouter(ProductView);

function SequenceImages() {
  const { choices } = useContext(ChoicesContext);

  if (choices.productSelected === "Chorus 1") {
    return <Chorus1SeqImages />;
  } else if (choices.productSelected === "Chorus 1 Complete") {
    return <Chorus1CompleteSeqImages />;
  } else if (choices.productSelected === "Chorus 2") {
    return <Chorus2SeqImages />;
  } else if (choices.productSelected === "Chorus 2+") {
    return <Chorus2PlusSeqImages />;
  } else if (choices.productSelected === "Chorus 3") {
    return <Chorus3SeqImages />;
  }
}

function UIFeatures() {
  const { choices } = useContext(ChoicesContext);

  if (choices.productSelected === "Chorus 1") {
    return <Chorus1UIFeatures />;
  } else if (choices.productSelected === "Chorus 1 Complete") {
    return <Chorus1CompleteUIFeatures />;
  } else if (choices.productSelected === "Chorus 2") {
    return <Chorus2UIFeatures />;
  } else if (choices.productSelected === "Chorus 2+") {
    return <Chorus2PlusUIFeatures />;
  } else if (choices.productSelected === "Chorus 3") {
    return <Chorus3UIFeatures />;
  }
}
